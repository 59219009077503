import axios from '@/lib/axios'
import formatMealtime from '@/utils/formatMealTime'
import { format } from 'date-fns'

export const mealplan = {
  state: {
    date: new Date(),
    meals: [
      {
        mealtime: 'Sarapan',
        total_calorie: 0,
        total_carbohydrate: 0,
        total_protein: 0,
        total_sugar: 0,
        total_fat: 0,
        total_fiber: 0,
        meals: null,
      },
      {
        mealtime: 'Makan siang',
        total_calorie: 0,
        total_carbohydrate: 0,
        total_protein: 0,
        total_sugar: 0,
        total_fat: 0,
        total_fiber: 0,
        meals: null,
      },
      {
        mealtime: 'Makan malam',
        total_calorie: 0,
        total_carbohydrate: 0,
        total_protein: 0,
        total_sugar: 0,
        total_fat: 0,
        total_fiber: 0,
        meals: null,
      },
      {
        mealtime: 'Cemilan',
        total_calorie: 0,
        total_carbohydrate: 0,
        total_protein: 0,
        total_sugar: 0,
        total_fat: 0,
        total_fiber: 0,
        meals: null,
      },
    ],
    nutritionNeeded: null,
    mealTime: '',
    lastMealAll: [],
    lastMealWithTime: [],
    popularFoods: [],
    selectedFoods: [],
    selectedMeal: null,
    goal: 'fat-loss',
    dietCarbo: 'moderate',
    openSavedMealsForm: false,
    openSavedMealsEdit: false,
    startMealPlanDate: null,
    savedMealsFormValues: null,
    savedMealsList: [],
    selectedSavedMeals: [],
    startMealPlanDate: null,
    foodKeywordSuggestions: [],
  },
  reducers: {
    setOpenSavedMealsForm(state, payload) {
      return {
        ...state,
        openSavedMealsForm: payload,
      }
    },
    setOpenSavedMealsEdit(state, payload) {
      return {
        ...state,
        openSavedMealsEdit: payload,
      }
    },
    setDate(state, payload) {
      return {
        ...state,
        date: payload,
      }
    },
    setMeals(state, payload) {
      return {
        ...state,
        meals: payload,
      }
    },
    setNutritionNeeded(state, payload) {
      return {
        ...state,
        nutritionNeeded: payload,
      }
    },
    setMealTime(state, payload) {
      return {
        ...state,
        mealTime: payload,
      }
    },
    setPopularFoods(state, payload) {
      return {
        ...state,
        popularFoods: payload,
      }
    },
    setLastMealAll(state, payload) {
      return {
        ...state,
        lastMealAll: payload,
      }
    },
    setLastMealWithTime(state, payload) {
      return {
        ...state,
        lastMealWithTime: payload,
      }
    },
    addSelectedFoods(state, payload) {
      if (state.selectedFoods.find((food) => food.food_id === payload.id)) {
        return state
      }
      if (!payload.nutritionLabel || !payload.selectedUnit) {
        return {
          ...state,
          selectedFoods: [
            ...state.selectedFoods,
            {
              name: payload.name,
              food_id: payload.id,
              date: format(state.date, 'yyyy-MM-dd'),
              serving: payload.food_details.data[0].detail,
              unit: payload.food_details.data[0].unit
                ? payload.food_details.data[0].unit
                : payload.food_details.data[0].name,
              amount: payload.food_details.data[0].amount,
              quantity: payload.portion ? payload.portion : 1,
              calorie: payload.food_details.data[0].calories,
              carbohydrate: payload.food_details.data[0].carbohydrate,
              protein: payload.food_details.data[0].protein,
              sugar: payload.food_details.data[0].sugar,
              fat: payload.food_details.data[0].fat,
              fiber: payload.food_details.data[0].fiber,
              food_detail_id: payload.food_details.data[0].id,
              food_details: payload.food_details,
              label: payload.label,
            },
          ],
        }
      } else {
        return {
          ...state,
          selectedFoods: [
            ...state.selectedFoods,
            {
              name: payload.name,
              food_id: payload.id,
              date: format(state.date, 'yyyy-MM-dd'),
              serving: payload.selectedUnit.detail,
              unit: payload.selectedUnit.unit ? payload.selectedUnit.unit : payload.selectedUnit.name,
              amount: parseInt(payload.selectedUnit.amount),
              quantity: payload.portion,
              calorie: payload.nutritionLabel[0].value,
              carbohydrate: payload.nutritionLabel[1].value,
              fat: payload.nutritionLabel[2].value,
              protein: payload.nutritionLabel[3].value,
              sugar: payload.nutritionLabel[4].value,
              fiber: payload.nutritionLabel[5].value,
              food_detail_id: payload.selectedUnit.id,
              food_details: payload.food_details,
              label: payload.label,
            },
          ],
        }
      }
    },
    updateSelectedFoods(state, payload) {
      return {
        ...state,
        selectedFoods: state.selectedFoods.map((food) => {
          if (food.id === payload.food_id || food.food_id === payload.food_id) {
            if (!payload.nutritionLabel || !payload.selectedUnit) {
              return {
                ...food,
                serving: payload.food_details.data[0].detail,
                unit: payload.food_details.data[0].unit
                  ? payload.food_details.data[0].unit
                  : payload.food_details.data[0].name,
                amount: payload.food_details.data[0].amount,
                quantity: payload.portion ? payload.portion : 1,
                calorie: payload.food_details.data[0].calories,
                carbohydrate: payload.food_details.data[0].carbohydrate,
                protein: payload.food_details.data[0].protein,
                sugar: payload.food_details.data[0].sugar,
                fat: payload.food_details.data[0].fat,
                fiber: payload.food_details.data[0].fiber,
                food_detail_id: payload.food_details.data[0].id,
                food_details: payload.food_details,
              }
            } else {
              return {
                ...food,
                serving: payload.selectedUnit.detail,
                unit: payload.selectedUnit.unit ? payload.selectedUnit.unit : payload.selectedUnit.name,
                amount: parseInt(payload.selectedUnit.amount),
                quantity: payload.portion,
                calorie: payload.nutritionLabel[0].value,
                carbohydrate: payload.nutritionLabel[1].value,
                fat: payload.nutritionLabel[2].value,
                protein: payload.nutritionLabel[3].value,
                sugar: payload.nutritionLabel[4].value,
                fiber: payload.nutritionLabel[5].value,
                food_detail_id: payload.selectedUnit.id,
                food_details: payload.food_details,
              }
            }
          }
          return food
        }),
      }
    },
    updateSavedMealsSelectedFoods(state, payload) {
      return {
        ...state,
        selectedFoods: state.selectedFoods.map((food) => {
          if (
            (food.object === 'Food' && food.id === payload.food_id) ||
            (payload.object === 'Food' && payload.id === food.food_id) ||
            food.food_id === payload.food_id
          ) {
            return {
              ...food,
              ...payload,
            }
          }
          return food
        }),
      }
    },
    removeSelectedFood(state, foodId) {
      return {
        ...state,
        selectedFoods: state.selectedFoods.filter((selectedFood) => selectedFood.food_id !== foodId),
      }
    },
    replaceSelectedFoods(state, payload) {
      return {
        ...state,
        selectedFoods: payload,
      }
    },
    resetSelectedFoods(state) {
      return {
        ...state,
        selectedFoods: [],
      }
    },
    setSelectedMeal(state, payload) {
      return {
        ...state,
        selectedMeal: payload,
      }
    },
    setGoal(state, payload) {
      return {
        ...state,
        goal: payload,
      }
    },
    setDietCarbo(state, payload) {
      return {
        ...state,
        dietCarbo: payload,
      }
    },
    setStartMealPlanDate(state, payload) {
      return {
        ...state,
        startMealPlanDate: payload,
      }
    },
    setSavedMealsList(state, payload) {
      return {
        ...state,
        savedMealsList: payload,
      }
    },
    updateSelectedSavedMeals(state, payload) {
      if (state.selectedSavedMeals?.find((item) => item.id === payload.id)) {
        return {
          ...state,
          selectedSavedMeals: state.selectedSavedMeals?.filter((item) => item.id !== payload.id),
        }
      }
      return {
        ...state,
        selectedSavedMeals: [...state.selectedSavedMeals, payload],
      }
    },
    addSelectedSavedMeals(state, payload) {
      if (!state.selectedSavedMeals?.some((item) => item.id === payload.id)) {
        return {
          ...state,
          selectedSavedMeals: [...state.selectedSavedMeals, payload],
        }
      } else {
        return state
      }
    },
    resetSelectedSavedMeals(state) {
      return {
        ...state,
        selectedSavedMeals: [],
      }
    },
    setSavedMealsFormValues(state, payload) {
      return {
        ...state,
        savedMealsFormValues: payload,
      }
    },
    resetSavedMealsFormValues(state) {
      return {
        ...state,
        savedMealsFormValues: null,
      }
    },
    setFoodKeywordSuggestions(state, payload) {
      return {
        ...state,
        foodKeywordSuggestions: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async getNutritionNeeded(payload, rootState) {
      return await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/nutrition-needed`, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
          params: {
            goal: payload.goal,
            diet_carbo: payload.diet_carbo,
            date: payload.date,
          },
        })
        .then((res) => {
          dispatch.mealplan.setNutritionNeeded(res.data.data)
          return res.data
        })
    },
    async getDashboard(payload, rootState) {
      return await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/dashboard`, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
          params: {
            date: format(rootState.mealplan.date, 'yyyy-MM-dd'),
          },
        })
        .then((res) => {
          dispatch.mealplan.setMeals(res.data.data)
          return res.data
        })
    },
    async getFoods(payload, rootState) {
      return await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/food`, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
          params: {
            search: payload.search,
            per_page: payload.per_page,
            lang: payload.lang,
          },
        })
        .then((res) => {
          return res.data
        })
    },
    // payload is array
    async createMealPlan(payload, rootState) {
      return await axios
        .post(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan`, payload, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },
    async createMealPlanWithSelectedFoods(payload, rootState) {
      const mappedFoods = rootState.mealplan.selectedFoods.map((food) => ({
        ...food,
        mealtime: formatMealtime(rootState.mealplan.mealTime),
      }))

      return await axios
        .post(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan`, mappedFoods, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          dispatch.mealplan.resetSelectedFoods()
          return res.data
        })
    },
    async getLastMealWithTime(payload, rootState) {
      return await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/last-meal`, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
          params: {
            mealtime: payload.mealtime,
            past_day: 30,
          },
        })
        .then((res) => {
          dispatch.mealplan.setLastMealWithTime(res.data.data)
          return res.data
        })
    },
    async getLastMealAll(payload, rootState) {
      return await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/last-meal`, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
          params: {
            past_day: 30,
          },
        })
        .then((res) => {
          dispatch.mealplan.setLastMealAll(res.data.data)
          return res.data
        })
    },
    async getPopularFoods(payload, rootState) {
      return await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/frequently-consumed-food`, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          dispatch.mealplan.setPopularFoods(res.data.data)
          return res.data
        })
    },
    async deleteMealPlan(payload, rootState) {
      return await axios
        .delete(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/${payload.id}`, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },
    async updateMealPlan(payload, rootState) {
      return await axios
        .patch(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/${payload.id}`, payload, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },
    async createCustomFood(payload, rootState) {
      return await axios
        .post(`${process.env.NEXT_PUBLIC_API_URL}/v1/custom-food`, payload, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },
    async deleteMultipleMealPlans(payload, rootState) {
      return await axios
        .delete(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan`, {
          data: {
            ids: payload,
          },
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          dispatch.mealplan.getDashboard()
          return res.data
        })
    },
    async getInitialFoodKeywordSuggestion(payload, rootState) {
      return await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/food-keyword-suggestion`, {
          params: {
            name: '',
          },
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          dispatch.mealplan.setFoodKeywordSuggestions(res.data.data)
          return res.data
        })
    },
    async getFoodKeywordSuggestion(payload, rootState) {
      return await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/food-keyword-suggestion`, {
          params: {
            name: payload.name,
          },
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },
    async createMealPlanFromRecommendation(payload, rootState) {
      return await axios
        .post(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/recommendation`, payload, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          dispatch.mealplan.setDate(new Date(payload.date))
          dispatch.mealplan.setStartMealPlanDate(null)
          return res.data
        })
    },
    async postisFavoriteMealplanRecommendation(payload, rootState) {
      return await axios
        .post(
          `${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/recommendation-favorite`,
          {
            id: payload.id,
            set: payload.isFavorite ? '1' : '0',
          },
          {
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${rootState.user.access_token}`,
            },
          },
        )
        .then((res) => {
          return res.data
        })
    },
    async getSavedMeals(payload, rootState) {
      return await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/saved-meals`, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${rootState.user.access_token}`,
          },
          params: {
            include: 'nutrition_data',
          },
        })
        .then((res) => {
          dispatch.mealplan.setSavedMealsList(res.data.data)
          return res.data
        })
    },
    async createMealPlanFromSavedMeals(payload, rootState) {
      return await axios
        .post(
          `${process.env.NEXT_PUBLIC_API_URL}/v1/mealplan/saved-meals/apply`,
          {
            ids: rootState.mealplan.selectedSavedMeals.map((meal) => meal.id),
            mealtime: formatMealtime(rootState.mealplan.mealTime),
            date: format(rootState.mealplan.date, 'yyyy-MM-dd'),
          },
          {
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${rootState.user.access_token}`,
            },
          },
        )
        .then((res) => {
          dispatch.mealplan.resetSelectedFoods()
          dispatch.mealplan.resetSelectedSavedMeals()
          return res.data
        })
    },
  }),
}
