export const chatDetail = {
  state: {
    selectedTopicID: '',
    selectedContact: null,
    selectedGroup: null,
    selectedHashedID: null,
  },
  reducers: {
    setSelectedGroup(state, payload) {
      return {
        ...state,
        selectedGroup: payload,
      }
    },
    setSelectedTopicId(state, payload) {
      return {
        ...state,
        selectedTopicID: payload,
      }
    },
    setSelectedContact(state, payload) {
      return {
        ...state,
        selectedContact: {
          topic: payload.name,
          name: payload.public?.fn,
          online: payload.online,
          seq: payload.seq,
          read: payload.read,
          recv: payload.recv,
          unread: payload.unread,
        },
      }
    },

    setSelectedHashedId(state, payload) {
      return {
        ...state,
        selectedHashedID: payload,
      }
    },
  },
  effects: (dispatch) => ({}),
}
