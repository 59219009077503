import { radioAnatomy, tabsAnatomy, switchAnatomy, checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyleConfig, extendTheme, theme as chakraTheme } from '@chakra-ui/react'

const colors = {
  logo: {
    primary: '#FF2C4B',
    secondary: '#002C3F',
    white: '#CC233C',
  },
  default: {
    line: '#D9DBE9',
    body: '#4E4B66',
    title: '#002C3F',
    placeholder: '#A0A3BD',
    label: '#6E7191',
    background: '#F4F7FE',
    pinkTransparent: 'rgba(255, 44, 75, 0.7)',
    grayTransparent: 'rgba(0, 0, 0, 0.15)',
    backgroundInput: '#F2F3F7',
    backgroundContent: '#F4F6FA',
  },
  primaryRed: {
    300: '#FFAAB7',
    400: '#FF6B81',
    500: '#FF2C4B',
    600: '#FF415D',
    700: '#E52744',
    800: '#CC233C',
    900: '#B21E35',
  },
  primaryBlue: {
    50: '#EEFBFC',
    100: '#C4F3F5',
    200: '#8CCCD9',
    300: '#22738B',
    400: '#00334A',
    500: '#002C3F',
    700: '#001F2B',
    900: '#00161E',
  },
  primaryBondiBlue: {
    50: '#B2E5E8',
    100: '#6DD1D7',
    200: '#4CC4CB',
    400: '#0099A2',
    500: '#00ABB5',
    600: '#00777E',
    800: '#00555A',
  },
  brandNew: {
    50: '#F1FBFC',
    100: '#E2F6F7',
    200: '#C5EDEF',
    300: '#A7E3E7',
    400: '#8ADADF',
    500: '#6DD1D7',
    600: '#529DA1',
    700: '#37696C',
    800: '#1B3436',
    900: '#0B1516',
  },
  dark: {
    100: '#FFFFFF',
    200: '#E8E8E8',
    400: '#BCBCBC',
    500: '#000000',
    600: '#797979',
    800: '#5A5A5A',
  },
  state: {
    success: '#3BC279',
    success2: '#C5F7DD',
    warning: '#E8A029',
    warning2: '#FFE8C3',
    danger: '#E9594C',
    danger2: '#FFD0CB',
    info: '#3E84E5',
    info2: '#CCE3FF',
  },
  primary: {
    whiteSolid: '#FFFFFF',
    daintree: '#012C3F',
    bondiBlue: '#00ABB5',
    ceriseRed: '#E42E6B',
    radicalRed: '#FF2C4C',
    100: '#FFD5DB',
  },
  secondary: {
    azureRadiance: '#00A0F4',
    yellowTurbo: '#FFEC01',
    blazeOrange: '#FF6B01',
    greenHaze: '#00A040',
    lavenderPink: '#FBB1D2',
    blue: '#185ADB',
    2: '#FF566F',
    dark: '#26343C',
  },
  button: {
    default: '#185ADA',
    hover: '#467BE2',
    pressed: '#1244A4',
    disabled: '#E1EBFF',
  },
}

const fonts = {}

const Button = {
  baseStyle: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    borderRadius: '6px',
  },
  variants: {
    solid: {
      bg: 'button.default',
      color: 'white',
      _hover: {
        bg: 'button.hover',
      },
      _active: {
        bg: 'button.pressed',
      },
      _disabled: {
        bg: '#E1EBFF',
        opacity: 1,
        color: 'white',
      },
    },
    solidSecondary: {
      fontSize: '12px',
      bg: '#E1EBFF',
      color: '#1244A4',
      _hover: {
        bg: '#E1EBFF',
      },
      _active: {
        bg: '#E1EBFF',
      },
      _disabled: {
        bg: 'button.disabled',
      },
    },
    outline: {
      bg: 'white',
      border: '2px solid',
      borderColor: 'button.default',
      color: 'button.default',
      _hover: {
        bg: 'white',
        borderColor: 'button.hover',
        color: 'button.hover',
      },
      _active: {
        borderColor: 'button.pressed',
        color: 'button.pressed',
      },
      _disabled: {
        borderColor: 'button.disabled',
        color: 'button.disabled',
      },
    },
    ghost: {
      bg: 'transparent',
      color: 'button.default',
      _hover: {
        bg: 'transparent',
        color: 'button.hover',
      },
      _active: {
        bg: 'transparent',
        color: 'button.pressed',
      },
      _disabled: {
        bg: 'transparent',
        color: 'button.disabled',
      },
    },
    link: {
      bg: 'transparent',
      padding: 0,
      lineHeight: 'normal',
      color: 'button.default',
      _hover: {
        color: 'button.hover',
        textDecoration: 'none',
      },
      _active: {
        color: 'button.pressed',
        textDecoration: 'none',
      },
      _disabled: {
        color: 'button.disabled',
        textDecoration: 'none',
      },
    },
    red: {
      bg: 'logo.primary',
      color: 'white',
    },
    blue: {
      bg: 'button.default',
      color: 'white',
      _hover: {
        bg: 'button.default',
        _disabled: {
          bg: 'button.default',
        },
      },
    },
    secondary: {
      bg: 'button.disabled',
      color: 'secondary.blue',
      _hover: {
        color: 'button.hover',
      },
      _active: {
        color: 'button.pressed',
      },
      _disabled: {
        color: 'dark.200',
      },
    },
    read: {
      bg: 'button.default',
      color: 'white',
      borderRadius: '5px',
      opacity: 1,
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '130%',
      _hover: {
        color: 'button.hover',
      },
      _active: {
        color: 'button.pressed',
      },
      _disabled: {
        bg: 'button.disabled',
        color: 'white',
        opacity: 1,
      },
    },
  },
}

const Heading = {
  baseStyle: {
    fontWeight: 700,
  },
  defaultProps: {
    size: 'md',
  },
}

const { definePartsStyle: defineRadioPartsStyle } = createMultiStyleConfigHelpers(radioAnatomy.keys)

const Radio = {
  baseStyle: defineRadioPartsStyle({
    control: {
      _checked: {
        bg: 'button.default',
      },
    },
  }),
  defaultProps: {
    size: 'lg',
  },
}

const { definePartsStyle: defineCheckboxPartsStyle } = createMultiStyleConfigHelpers(checkboxAnatomy.keys)
const Checkbox = {
  variants: {
    large: defineCheckboxPartsStyle({
      control: {
        width: '24px',
        height: '24px',
        borderRadius: '8px',
        borderWidth: '3px',
        borderColor: '#747474',
      },
      icon: {
        fontSize: '14px',
      },
    }),
  },
}

const Link = {
  baseStyle: {
    color: 'button.default',
    textDecoration: 'underline',
    fontWeight: 500,
  },
  variants: {
    info: {
      color: '#6E7191',
      marginTop: '4px',
    },
    lightBlue: {
      color: '#3E84E5',
    },
  },
}

const Text = {
  baseStyle: {
    color: 'logo.secondary',
    fontSize: '12px',
  },
  variants: {
    info: {
      fontSize: '12px',
      color: '#6E7191',
      marginTop: '4px',
    },
    error: {
      fontSize: '12px',
      color: 'state.danger',
      marginTop: '4px',
    },
  },
}

const Input = {
  baseStyle: {
    field: {
      bg: '#F2F3F7',
      borderRadius: '8px',
      _placeholder: {
        fontSize: '14px',
      },
    },
  },
  variants: {
    solid: {
      field: {
        bg: '#F2F3F7',
        borderRadius: '8px',
        pt: '32px',
        pb: '20px',
        _placeholder: {
          fontSize: '14px',
          color: '#F2F3F7',
        },
        _invalid: {
          borderColor: 'state.danger',
          borderWidth: '2px',
        },
        _focusVisible: {
          borderColor: 'button.default',
          borderWidth: '2px',
        },
      },
    },
    solidIcon: {
      field: {
        bg: '#F2F3F7',
        borderRadius: '8px',
        pt: '24px',
        pb: '28px',
        _placeholder: {
          fontSize: '14px',
          color: '#8D9091',
        },
        _invalid: {
          borderColor: 'state.danger',
          borderWidth: '2px',
        },
      },
    },
    solidRegular: {
      field: {
        bg: '#F2F3F7',
        borderRadius: '8px',
        pt: '20px',
        pb: '20px',
        _placeholder: {
          fontSize: '14px',
        },
        _invalid: {
          borderColor: 'state.danger',
          borderWidth: '2px',
        },
        _focusVisible: {
          borderColor: 'button.default',
          borderWidth: '2px',
        },
      },
    },
  },
}

const activeLabelStyles = {
  transform: 'scale(0.8) translateY(-16px)',
}

const Form = {
  variants: {
    floating: {
      field: {
        pointerEvents: 'none',
      },
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        '.chakra-input__group > input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label, .chakra-input__group > label + *':
          {
            ...activeLabelStyles,
          },
        label: {
          color: 'dark.600',
          top: 0,
          left: 0,
          zIndex: 2,
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          mx: 3,
          px: 1,
          my: 4,
          transformOrigin: 'left top',
          fontSize: '14px',
        },
      },
    },
  },
}

const Divider = defineStyleConfig({
  baseStyle: {
    borderWidth: '0px',
  },
})

const { definePartsStyle: defineTabsPartsStyle, defineMultiStyleConfig: defineTabsMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

const TabsPartsStyle = defineTabsPartsStyle({
  tab: {
    color: '#A0A3BD',
    fontWeight: 500,
    _selected: {
      color: 'logo.primary',
      borderColor: 'logo.primary',
    },
  },
})

const Tabs = defineTabsMultiStyleConfig({
  baseStyle: TabsPartsStyle,
  defaultProps: {
    variant: 'unstyled',
  },
})

const { definePartsStyle: defineSwitchPartsStyle, defineMultiStyleConfig: defineSwitchMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys)
const SwitchPartsStyle = defineSwitchPartsStyle({
  track: {
    bg: 'button.default',
    _checked: {
      bg: 'button.default',
    },
  },
})

const Switch = defineSwitchMultiStyleConfig({
  variants: {
    dualSwitch: SwitchPartsStyle,
  },
})

const overrides = {
  ...chakraTheme,
  components: {
    Button,
    Input,
    Heading,
    Link,
    Text,
    Form,
    Radio,
    Divider,
    Tabs,
    Switch,
    Checkbox,
  },
  colors,
  fonts,
  fontWeights: {},
  fontSizes: {},
}

const customTheme = extendTheme(overrides)

export default customTheme
