import { createContext, useContext } from 'react'

// Create a context with a null default value
const TinodeContext = createContext(null)

// Custom hook to use the Tinode connection
export function useTinode() {
  const context = useContext(TinodeContext)
  if (context === null) {
    throw new Error('useTinode must be used within a TinodeProvider')
  }
  return context
}

export default TinodeContext
