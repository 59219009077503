import { init } from '@rematch/core'
import persistPlugin from '@rematch/persist'
import { createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { mealplan } from './models/mealplan'
import { measurement } from './models/measurement'
import { payment } from './models/payment'
import { recurring } from './models/recurring'
import { user } from './models/user'
import { workout } from './models/workout'
import { chat } from './models/chat'
import { chatDetail } from './models/chatDetail'

// Why do we need a transformer?
// Because we want to store the date as a Date object, not a string
// So we can just format(date) in the component instead of format(new Date(date)) everytime
const MealplanDateTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState) => {
    if (outboundState.date) {
      return { ...outboundState, date: new Date(outboundState.date) }
    }
    return outboundState
  },
  { whitelist: ['mealplan'] },
)

const persistConfig = {
  key: 'root',
  whitelist: ['user', 'payment', 'workout', 'recurring', 'mealplan', 'chatDetail'],
  version: 1,
  storage: storage,
  transforms: [MealplanDateTransform],
}

const store = init({
  models: {
    user,
    payment,
    mealplan,
    workout,
    measurement,
    recurring,
    chat,
    chatDetail,
  },
  plugins: [persistPlugin(persistConfig)],
})

export default store
