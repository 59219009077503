import axios from 'axios'

const headers = {
  accept: 'application/json',
}

if (typeof localStorage !== 'undefined') headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers,
})

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = localStorage.getItem('refresh_token')
      if (refreshToken) {
        return axios
          .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/clients/web/refresh`, {
            headers: {
              accept: 'application/json',
            },
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem('access_token', res.data.access_token)
              api.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
              return api(originalRequest)
            }
          })
      } else {
        localStorage.removeItem('access_token')
        window.location.href = '/auth/login'
      }
    }
    return Promise.reject(error)
  },
)

export default api
