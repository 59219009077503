import instance from '@/lib/axios'

export const GetWorkoutApi = (params) =>
  instance.get('/v1/workout', {
    params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })

export const GetWorkoutDetailApi = (workoutId) =>
  instance.get(`/v1/workout/program/${workoutId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })

export const GetWorkoutProgramDetailApi = (programId, params) =>
  instance.get(`/v1/workout/program/detail/${programId}`, {
    params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })

export const GetProgramHistoryApi = (programId, params) =>
  instance.get(`/v1/workout/program/${programId}/history`, {
    params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })

export const PostCreateHistoryApi = (programId, payload) =>
  instance.post(`/v1/workout/program/${programId}/history`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })

export const PatchUpdateHistoryApi = (programId, payload) =>
  instance.patch(`/v1/workout/program/${programId}/history`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
