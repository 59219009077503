export const measurement = {
  state: {
    // for measurement comparison feature
    firstMeasurement: null,
    secondMeasurement: null,
    diffMeasurement: null,
    selectedOption: '',
    measurementShareData: null,
  },
  reducers: {
    setFirstMeasurement(state, payload) {
      return {
        ...state,
        firstMeasurement: payload,
      }
    },
    setSecondMeasurement(state, payload) {
      return {
        ...state,
        secondMeasurement: payload,
      }
    },
    setDiffMeasurement(state, payload) {
      return {
        ...state,
        diffMeasurement: payload,
      }
    },
    setSelectedOption(state, payload) {
      return {
        ...state,
        selectedOption: payload,
      }
    },
    setMeasurementShareData(state, payload) {
      return {
        ...state,
        measurementShareData: payload,
      }
    },
  },
  effects: (dispatch) => ({}),
}
