export default class TinodeConfig {
  _host
  _appName
  _apiKey
  _transport
  _username
  _password
  _subscribe
  _topic
  _tinode
  _secure

  constructor() {
    TinodeConfig._host = process.env.NEXT_PUBLIC_CHAT_HOST
    TinodeConfig._appName = 'App V2'
    TinodeConfig._apiKey = process.env.NEXT_PUBLIC_CHAT_API_KEY
    TinodeConfig._transport = 'ws'
    TinodeConfig._secure = process.env.NEXT_PUBLIC_CHAT_SECURE == 'false' ? false : true
    TinodeConfig._login = false
  }
}
