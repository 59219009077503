import axios from '@/lib/axios'

export const user = {
  state: {
    user: null,
    access_token: null,
    refresh_token: null,
    countries: [],
    rememberMe: true,
    showTutorialPremiumPopup: false,
  },
  reducers: {
    setUser(state, payload) {
      return {
        ...state,
        user: payload,
      }
    },
    setAccessToken(state, payload) {
      if (payload) localStorage.setItem('access_token', payload)
      else localStorage.removeItem('access_token')
      return {
        ...state,
        access_token: payload,
      }
    },
    setRefreshToken(state, payload) {
      return {
        ...state,
        refresh_token: payload,
      }
    },
    setCountries(state, payload) {
      return {
        ...state,
        countries: payload,
      }
    },
    setRememberMe(state, payload) {
      return {
        ...state,
        rememberMe: payload,
      }
    },
    setShowTutorialPremiumPopup(state, payload) {
      return {
        ...state,
        showTutorialPremiumPopup: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async login(payload, rootState) {
      const { email, password, rememberMe } = payload
      const { data } = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/v1/clients/web/login`,
        {
          email,
          password,
        },
        {
          headers: {
            accept: 'application/json',
          },
        },
      )
      dispatch.user.setAccessToken(data.access_token)
      dispatch.user.setRememberMe(rememberMe)
      if (rememberMe) {
        dispatch.user.setRefreshToken(data.refresh_token)
      }
    },
    async register(payload, rootState) {
      const { name, email, password } = payload
      return await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/meta-application`, {
          headers: {
            accept: 'application/json',
          },
        })
        .then(async (res) => {
          const { data } = await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL}/v1/register`,
            {
              name,
              email,
              password,
              verification_url: res.data.meta_data.verification_url,
            },
            {
              headers: {
                accept: 'application/json',
              },
            },
          )
          return data
        })
    },
    async logout(payload, rootState) {
      await axios.delete(`${process.env.NEXT_PUBLIC_API_URL}/v1/logout`, {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${rootState.user.access_token}`,
        },
      })
    },
    async socialLogin(payload, rootState) {
      const { provider, provider_access_token } = payload
      return await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/v1/social-login`,
        {
          provider,
          provider_access_token,
        },
        {
          headers: {
            accept: 'application/json',
          },
        },
      )
    },
    async verifyEmail(payload, rootState) {
      const { id, hash, expires, signature } = payload
      await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/v1/email/verify/${id}/${hash}`,
        {},
        {
          headers: {
            accept: 'application/json',
          },
          params: {
            expires,
            signature,
          },
        },
      )
    },
    async verifyForgotPasswordToken(payload, rootState) {
      const { email, access_token } = payload
      await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/v1/password/forgot/verify`,
        {
          email,
          access_token,
        },
        {
          headers: {
            accept: 'application/json',
          },
        },
      )
    },
    async getProfile(payload, rootState) {
      const { data } = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/v1/profile`, {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${rootState.user.access_token}`,
        },
      })
      dispatch.user.setUser(data.data)
      return data.data
    },
    async sendVerificationEmail(payload, rootState) {
      await axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/v1/meta-application`, {
          headers: {
            accept: 'application/json',
          },
        })
        .then(async (res) => {
          await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL}/v1/email/verification-notification`,
            {
              verification_url: res.data.meta_data.verification_url,
            },
            {
              headers: {
                accept: 'application/json',
                authorization: `Bearer ${rootState.user.access_token}`,
              },
            },
          )
        })
    },
    async updateProfileLocal(payload, rootState) {
      dispatch.user.setUser({ ...rootState.user.user, ...payload })
    },
    async updateProfile(payload, rootState) {
      return await axios
        .patch(`${process.env.NEXT_PUBLIC_API_URL}/v1/profile`, payload, {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },
    async updateAvatar(avatar, rootState) {
      const formData = new FormData()
      formData.append('_method', 'PATCH')
      formData.append('avatar', avatar)
      return await axios
        .post(`${process.env.NEXT_PUBLIC_API_URL}/v1/profile`, formData, {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },
    async forgotPassword(payload, rootState) {
      const { email, type } = payload
      await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/v1/password/forgot`,
        {
          email,
          type,
        },
        {
          headers: {
            accept: 'application/json',
          },
        },
      )
    },
    async verifyForgotPasswordToken(payload, rootState) {
      const { email, token } = payload
      return await axios
        .post(
          `${process.env.NEXT_PUBLIC_API_URL}/v1/password/forgot/verify`,
          {
            email,
            token,
          },
          {
            headers: {
              accept: 'application/json',
            },
          },
        )
        .then((res) => {
          return res.status
        })
        .catch((err) => {
          return err.response.status
        })
    },
    async resetPassword(payload, rootState) {
      const { email, token, password } = payload
      return await axios
        .post(
          `${process.env.NEXT_PUBLIC_API_URL}/v1/password/reset`,
          {
            email,
            token,
            password,
          },
          {
            headers: {
              accept: 'application/json',
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            return true
          }
        })
    },
    async getCountries(payload, rootState) {
      const { data } = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/v1/locations/countries`, {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${rootState.user.access_token}`,
        },
      })
      dispatch.user.setCountries(data.data)
    },
    async createOTP(payload, rootState) {
      const { phone, phone_country_code } = payload
      return await axios
        .post(
          `${process.env.NEXT_PUBLIC_API_URL}/v1/otp/send`,
          {
            phone,
            phone_country_code,
          },
          {
            headers: {
              accept: 'application/json',
              authorization: `Bearer ${rootState.user.access_token}`,
            },
          },
        )
        .then((res) => {
          if (res.status === 204) {
            return true
          }
        })
    },
    async deactivateAccount(payload, rootState) {
      return await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/v1/profile/deactivate`,
        {
          reason: payload.reason,
        },
        {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        },
      )
    },
    async reactivateAccount(payload, rootState) {
      return await axios
        .post(
          `${process.env.NEXT_PUBLIC_API_URL}/v1/profile/reactivate`,
          {},
          {
            headers: {
              accept: 'application/json',
              authorization: `Bearer ${rootState.user.access_token}`,
            },
          },
        )
        .then((res) => {
          if (res.status === 202) {
            return true
          }
        })
    },
  }),
}
