import axios from '@/lib/axios'
import TinodeApi from '@/lib/tinode/TinodeApi'

export const chat = {
  state: {
    instance: null,
    contacts: [],
    showedContacts: [],

    messagesCache: new Map(),
    currentMessages: [],
    selectedTopicID: '',

    profile: {
      displayName: '',
      avatar: {},
      userID: '',
    },
    chatList: [],
    members: [],

    isConnect: false,
    isFullDisconnect: false,
    disconnectTimeout: null,
    typing: [],
    typingTimeout: [],
    suddenlyGone: '',
    firstSubs: true,
  },
  reducers: {
    setInstance(state, payload) {
      return {
        ...state,
        instance: payload,
      }
    },
    setContactList(state, payload) {
      return {
        ...state,
        contacts: payload,
      }
    },
    setShowedContact(state, payload) {
      return {
        ...state,
        showedContacts: payload,
      }
    },
    setCurrentMessage(state, payload) {
      return {
        ...state,
        currentMessages: payload,
      }
    },
    setMessageCache(state, payload) {
      return {
        ...state,
        messagesCache: payload,
      }
    },

    setProfile(state, payload) {
      return {
        ...state,
        profile: payload,
      }
    },

    setSelectedTopicId(state, payload) {
      return {
        ...state,
        selectedTopicID: payload,
      }
    },

    clearContactsState(state, payload) {
      return {
        ...state,
        selectedTopicID: '',
        contacts: [{}],
      }
    },

    clearMessagesState(state) {
      return {
        ...state,
        messagesCache: new Map(),
        currentMessages: [],
      }
    },

    setChatList(state, payload) {
      return {
        ...state,
        chatList: payload,
      }
    },

    setMembers(state, payload) {
      return {
        ...state,
        members: payload,
      }
    },
    setIsConnect(state, payload) {
      return {
        ...state,
        isConnect: payload,
      }
    },

    setIsFullDisconnect(state, payload) {
      return {
        ...state,
        isFullDisconnect: payload,
      }
    },
    setDisconnectTimeout(state, payload) {
      return {
        ...state,
        disconnectTimeout: payload,
      }
    },

    setTyping(state, payload) {
      return {
        ...state,
        typing: payload,
      }
    },
    setTypingTimeout(state, payload) {
      return {
        ...state,
        typingTimeout: payload,
      }
    },

    setSuddenlyGone(state, payload) {
      return {
        ...state,
        suddenlyGone: payload,
      }
    },

    setFistSubs(state, payload) {
      return {
        ...state,
        firstSubs: payload,
      }
    },
  },
  effects: (dispatch) => ({
    storeMessage(payload, rootState) {
      let stateCache = rootState.chat.messagesCache
      let messagesCache = new Map(stateCache).get(payload.topic)

      if (!new Map(stateCache).has(payload.topic)) {
        // initialize new array of messages at map location rootState.messagesCache[topicID]
        stateCache.set(payload.topic, [payload])

        if (payload.topic == rootState.chat.selectedTopicID) {
          dispatch.chat.setCurrentMessage(messagesCache.get(payload.topic))
        }
      } else {
        // append message to existing messagesCache array
        if (!messagesCache.find((item) => item.seq == payload.seq)) {
          messagesCache.push(payload)
          messagesCache.sort((a, b) => {
            return new Date(b.ts) - new Date(a.ts)
          })
        }
      }

      dispatch.chat.setMessageCache(stateCache)
    },
    deleteMessage(payload, rootState) {
      let stateCache = rootState.chat.messagesCache
      let messagesCache = new Map(stateCache).get(payload.topic)

      payload.delseq.forEach((seq) => {
        if (messagesCache.find((item) => item.seq == seq.low)) {
          var index = messagesCache
            .map(function (o) {
              return o.seq
            })
            .indexOf(seq.low)
          if (index !== -1) {
            messagesCache.splice(index, 1)
          }
        }
      })

      dispatch.chat.setMessageCache(stateCache)
    },
    deleteSingleMessage(payload, rootState) {
      let stateCache = rootState.chat.messagesCache
      let messagesCache = new Map(stateCache).get(payload.topic)

      if (messagesCache.find((item) => item.seq == payload.seq)) {
        var index = messagesCache
          .map(function (o) {
            return o.seq
          })
          .indexOf(payload.seq)
        if (index !== -1) {
          messagesCache.splice(index, 1)
        }
      }

      dispatch.chat.setMessageCache(stateCache)
    },
    selectTopic(payload, rootState) {
      dispatch.chat.setSelectedTopicId(payload)
    },

    async getChatList(payload, rootState) {
      return await axios
        .get(`/v1/chat/group`, {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          dispatch.chat.setChatList(res.data.data)
          const contacts = res.data.data
            .filter(function (row) {
              return row.subscription_status.status
            })
            .map(function (row) {
              return row.tinode_channel_id
            })
          dispatch.chat.setShowedContact(contacts)
        })
    },

    async joinGroupChat(payload, rootState) {
      return await axios
        .post(
          `/v1/chat/group/${payload}/join`,
          {},
          {
            headers: {
              accept: 'application/json',
              authorization: `Bearer ${rootState.user.access_token}`,
            },
          },
        )
        .then((res) => {
          return res
        })
    },

    async leaveGroupChat(payload, rootState) {
      return await axios
        .post(
          `/v1/chat/group/${payload}/leave`,
          {},
          {
            headers: {
              accept: 'application/json',
              authorization: `Bearer ${rootState.user.access_token}`,
            },
          },
        )
        .then((res) => {
          return res
        })
    },
    async uploadImage(payload, rootState) {
      return await axios
        .post(`/v1/chat-files/images`, payload, {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },

    storeMembers(payload, rootState) {
      let stateCache = rootState.chat.members
      let groups = stateCache[payload.topic]

      if (!groups) {
        stateCache[payload.topic] = payload.members
      } else {
        payload.members.forEach((item) => {
          if (!groups.find((res) => res.user == item.user)) {
            groups.push(item)
          }
        })
        stateCache[payload.topic] = groups
      }

      dispatch.chat.setMembers(stateCache)
    },

    getCurrentLastSeq(payload, rootState) {
      const topic = rootState.chatDetail.selectedTopicID
      let messagesCache = new Map(rootState.chat.messagesCache).get(topic)

      if (!messagesCache.length) return 1

      messagesCache.sort((a, b) => {
        return b.seq - a.seq
      })

      return messagesCache[0].seq
    },

    updateReadContacts(payload, rootState) {
      const contacts = rootState.chat.contacts
      const findIndex = contacts.findIndex((item) => item.name === payload)
      if (findIndex >= 0) {
        contacts[findIndex].unread = 0

        dispatch.chat.setContactList(contacts)
      }
    },

    addOrUpdateContactList(payload, rootState) {
      if (rootState.chat.firstSubs) {
        dispatch.chat.setContactList(payload)
        dispatch.chat.setFistSubs(false)
      } else {
        let stateCache = rootState.chat.contacts
        stateCache.concat(payload)

        dispatch.chat.setContactList(stateCache)
        dispatch.chat.getChatList()
      }
    },

    async findChat(payload, rootState) {
      return await axios
        .get(`/v1/chat/group/${payload}`, {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },

    storeTyping(payload, rootState) {
      let stateCache = rootState.chat.typing

      stateCache[payload.topic] = payload.from

      dispatch.chat.setTyping(stateCache)

      const currTimeout = rootState.chat.typingTimeout[payload.topic]

      if (currTimeout) {
        clearTimeout(currTimeout)
      }
      const typingTimeout = setTimeout(() => {
        stateCache[payload.topic] = null

        dispatch.chat.setTyping(stateCache)
      }, 2000)

      dispatch.chat.setTypingTimeout(typingTimeout)
    },
  }),
}
