import store from '@/store'
import TinodeConfig from './TinodeConfig'
import { useTinode } from './TinodeContext'

const TinodeApi = (tinodeInstance) => {
  let tinode = tinodeInstance ? tinodeInstance : useTinode
  let reconnectTry = 0

  const handleConnect = (user, dispatch) => {
    if (tinode) {
      tinode
        .connect()
        .then(() => {
          login(user.user)
          dispatch.chat.setIsConnect(true)
          reconnectTry = 0
        })
        .then((ctrl) => {
          setTimeout(async (_) => {
            await subscribe(user, dispatch)
            tinode.onMessage = () => {
              dispatchMessage(dispatch, user)
            }
          }, 500)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const login = async (user) => {
    if (tinode && !TinodeConfig._login) {
      try {
        const result = await tinode.login('basic', user.client_secret)
        if (result.code >= 200 && result.code < 300) {
          TinodeConfig._login = true
        } else {
          console.log('Failed to login:', result.text)
          TinodeConfig._login = false
        }
      } catch (error) {
        console.error('Error logging in:', error)
        TinodeConfig._login = false
      }
    } else {
      TinodeConfig._login = false
      tinode.disconnect()
    }
  }

  const subscribe = async (user, dispatch) => {
    if (tinode) {
      try {
        setTimeout((_) => {
          var me = tinodeInstance.getMeTopic()

          dispatchMessage(dispatch, user)
          // Subscribe, fetch topic description and the list of contacts.
          me.subscribe({ what: 'sub desc data info' })
        }, 0)
      } catch (error) {
        console.error('Error subscribe in:', error)
        TinodeConfig._subscribe = false
      }
    }
  }

  const reSubscribe = async (topic) => {
    try {
      await tinodeInstance.leave('me')
      await tinodeInstance
        .subscribe('me', {
          data: {
            limit: 20,
          },
          desc: {
            ims: new Date().toISOString(),
          },
          what: 'data sub desc info',
        })
        .then(() => {
          if (topic) {
            tinodeInstance.subscribe(topic, {
              data: {
                limit: 20,
              },
              desc: {
                ims: new Date().toISOString(),
              },
              what: 'data sub desc info',
            })
          }
        })
    } catch (error) {
      console.error('Error subscribe in:', error)
    }
  }

  const logout = () => {
    tinodeInstance.disconnect()
  }

  const dispatchMessage = (dispatch, user) => {
    var me = tinodeInstance.getMeTopic()
    var fnd = tinodeInstance.getFndTopic()

    me.onMeta = function (meta) {
      if (meta.sub) {
        let contactsList = []
        meta.sub.forEach((sub) => {
          let topic = tinodeInstance.getTopic(sub.topic)
          topic
            .subscribe({
              data: {
                limit: 20,
              },
              desc: {
                ims: new Date().toISOString(),
              },
              what: 'data sub desc info',
            })
            .then(() => {
              contactsList.push(topic)
            })

          topic.onMeta = function (tMeta) {
            if (tMeta.sub) {
              dispatch.chat.storeMembers({ topic: sub.topic, members: tMeta.sub })
            }
          }
          topic.onPres = function (pres) {
            if (pres.delseq) {
              dispatch.chat.deleteMessage(pres)
            }
          }
        })
        dispatch.chat.addOrUpdateContactList(contactsList)
      }
    }

    tinodeInstance.onDataMessage = function (data) {
      dispatch.chat.storeMessage(data)
    }
    tinodeInstance.onMeta = function (meta) {
      console.log('{meta} message received:', meta)
    }
    tinodeInstance.onMetaDesc = function (meta) {
      console.log('{meta desc} message received:', meta)
    }

    tinodeInstance.onInfoMessage = function (info) {
      if (info.what == 'kp') {
        dispatch.chat.storeTyping(info)
      }
    }

    tinodeInstance.onDisconnect = async function () {
      TinodeConfig._login = false
      dispatch.chat.setIsConnect(false)
    }

    tinodeInstance.onConnect = function () {
      dispatch.chat.setIsConnect(true)
      handleConnect(user, dispatch)
    }

    me.onMetaDesc = function (meta) {
      if (meta.name == 'me') {
        if (meta.public) {
          dispatch.chat.setProfile({
            userID: tinodeInstance.getCurrentUserID(),
            displayName: meta.public.fn,
            avatar: meta.public.photo,
          })
        }
      }
    }

    me.onPres = function (pres) {
      if (pres.what == 'gone') {
        dispatch.chat.getChatList()
        dispatch.chat.setSuddenlyGone(pres.src)
      }
    }

    fnd.onMeta = function (meta) {
      // console.log('fnd.onMeta triggered:', meta)
    }

    fnd.onMetaDesc = function (meta) {
      // console.log('fnd.onMetaDesc triggered:', meta)
    }
  }

  const readChat = (topic, seq) => {
    tinodeInstance.note(topic, 'read', seq)
  }

  const broadcastTyping = (topic) => {
    tinodeInstance.noteKeyPress(topic)
  }

  const updateTopicPresence = (payload) => {
    let topic = tinodeInstance.getTopic(payload.topicID)
    topic.online = payload.presence
  }

  const getMessages = (topic) => {
    const thisTopic = tinodeInstance.getTopic(topic)
    return thisTopic.getMessagesPage(20, false)
  }

  const getUserAttr = (topic, userId) => {
    if (tinode) {
      const thisTopic = tinode.getTopic(userId)
      const userTopic = thisTopic.userDesc(userId)
      let user = {
        name: 'Member GGL',
        avatar: '',
      }
      if (userTopic && userTopic.public) {
        user = {
          name: userTopic.public.fn,
          avatar: userTopic.public.photo,
        }
      }

      return user
    }
  }

  const getMembers = (topic) => {
    if (tinode) {
      const thisTopic = tinode.getTopic(topic)
      const userTopic = thisTopic.userDesc(topic)
      thisTopic.onMeta = function (meta) {
        console.log(meta)
      }
    }
  }

  const publishMessage = (topic, message, from, isReply = false) => {
    if (!message) return
    let selectedTopicID = topic
    let client = tinodeInstance
    let selectedTopic = client.getTopic(selectedTopicID)
    let msg = selectedTopic.createMessage(message)

    // Message is outgoing, so assign the client's ID to msg.form
    if (!isReply) {
      msg.content = message
      msg.head = undefined
    }
    msg.from = from

    selectedTopic.publishMessage(msg)
  }

  const publishImage = (topic, message, from) => {
    if (!message) return
    let selectedTopicID = topic
    let client = tinodeInstance
    let selectedTopic = client.getTopic(selectedTopicID)
    let msg = selectedTopic.createMessage(message)
    msg.content = message
    msg.from = from
    msg.head = undefined

    selectedTopic.publishMessage(msg)
  }

  const deleteMessage = (topic, seq, hard) => {
    const thisTopic = tinodeInstance.getTopic(topic)
    thisTopic.delMessagesList([seq], hard)
  }

  return {
    handleConnect,
    login,
    subscribe,
    readChat,
    logout,
    updateTopicPresence,
    publishMessage,
    dispatchMessage,
    getMessages,
    getUserAttr,
    reSubscribe,
    getMembers,
    deleteMessage,
    publishImage,
    broadcastTyping,
  }
}

export default TinodeApi
