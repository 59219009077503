import { useEffect, useState, useMemo } from 'react'
import TinodeContext from './TinodeContext'
import TinodeConfig from './TinodeConfig'
import TinodeApi from './TinodeApi'
import { useDispatch, useSelector } from 'react-redux'
import { usePathname } from 'next/navigation'

function TinodeProvider({ children }) {
  const [tinode, setTinode] = useState(null)
  new TinodeConfig()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const chat = useSelector((state) => state.chat)
  const pathname = usePathname()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!user.user) return
      if (pathname != '/mentorship' && !pathname?.includes('chat')) {
        if (chat.instance) {
          TinodeConfig._login = false
          chat.instance.disconnect()
          dispatch.chat.setInstance(null)
        }
        return
      }
      // Import Tinode dynamically only on the client side
      import('tinode-sdk').then(({ Tinode }) => {
        let tinodeInstance = chat.instance

        if (!tinodeInstance) {
          tinodeInstance = new Tinode({
            appName: TinodeConfig._appName,
            host: TinodeConfig._host,
            apiKey: TinodeConfig._apiKey,
            transport: TinodeConfig._transport,
            secure: TinodeConfig._secure,
          })

          const { handleConnect } = TinodeApi(tinodeInstance)
          handleConnect(user, dispatch)
        }
        dispatch.chat.setInstance(tinodeInstance)

        window.addEventListener('offline', function () {
          TinodeConfig._login = false
          dispatch.chat.setIsConnect(false)
          // Set Full Disconnect in 1 minute
          dispatch.chat.setDisconnectTimeout(
            setTimeout(() => {
              dispatch.chat.setIsFullDisconnect(true)
              tinodeInstance.disconnect()
            }, 1 * 60000),
          )
        })

        window.addEventListener('online', function () {
          const { handleConnect } = TinodeApi(tinodeInstance)
          dispatch.chat.setIsConnect(true)
          handleConnect(user, dispatch)

          // Clear current timeout and set to default
          clearTimeout(chat.disconnectTimeout)
          dispatch.chat.setDisconnectTimeout(null)
          dispatch.chat.setIsFullDisconnect(false)
        })

        setTinode(tinodeInstance)
      })
    }
  }, [pathname])

  return <TinodeContext.Provider value={tinode}>{children}</TinodeContext.Provider>
}

export default TinodeProvider
