import axios from '@/lib/axios'

export const recurring = {
  state: {
    package: null,
    paymentSelected: null,
    paymentMethod: null,
    payment: null,
    customerId: null,
    paymentMethodId: null,
    userInformation: null,
  },
  reducers: {
    setPackage(state, payload) {
      return {
        ...state,
        package: payload,
      }
    },
    setPaymentSelected(state, payload) {
      return {
        ...state,
        paymentSelected: payload,
      }
    },
    setPaymentMethod(state, payload) {
      return {
        ...state,
        paymentMethod: payload,
      }
    },
    setPayment(state, payload) {
      return {
        ...state,
        payment: payload,
      }
    },
    setCustomerId(state, payload) {
      return {
        ...state,
        customerId: payload,
      }
    },
    setPaymentMethodId(state, payload) {
      return {
        ...state,
        paymentMethodId: payload,
      }
    },
    setUserInformation(state, payload) {
      return {
        ...state,
        userInformation: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async getRecurringPackage(payload, rootState) {
      return await axios
        .get(`/v1/packages/${process.env.NEXT_PUBLIC_RECURRING_PACKAGE_SLUG}`, {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          dispatch.recurring.setPackage(res.data.data)
        })
    },
    async processPayment(payload, rootState) {
      return await axios
        .post(`/v1/payment/recurring`, payload, {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },
    async createPlan(payload, rootState) {
      return await axios
        .post(`/v1/recurring/process`, payload, {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          dispatch.recurring.setPayment(res.data.data)
          return res.data
        })
    },
    async processCancel(payload, rootState) {
      return await axios.post(`/v1/recurring/cancel`, payload, {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${rootState.user.access_token}`,
        },
      })
    },
  }),
}
