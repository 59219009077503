import { ChakraProvider } from '@chakra-ui/react'
import { Montserrat } from '@next/font/google'
import dynamic from 'next/dynamic'
import 'react-day-picker/dist/style.css'
import { Provider } from 'react-redux'
import 'react-spring-bottom-sheet/dist/style.css'
import store from '../store'
import '../styles/bottomsheet-container.css'
import '../styles/group-chat.css'
import '../styles/ggl-datepicker.css'
import '../styles/global.css'
import '../styles/md-container.css'
import customTheme from '../styles/theme.js'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'

const cacheTime = 1000 * 60 * 60 * 24 // 24 hours
import TinodeProvider from '@/lib/tinode/TinodeProvider'

const montserrat = Montserrat({
  subsets: ['latin'],
  display: 'swap',
})

const theme = {
  ...customTheme,
  fonts: {
    ...customTheme.fonts,
    body: montserrat.style.fontFamily,
    heading: montserrat.style.fontFamily,
  },
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
})

const asyncStoragePersister = createAsyncStoragePersister({
  storage: typeof window !== 'undefined' ? window.localStorage : undefined,
})

// set persisted query key
const shouldPersist = [
  'workout-list',
  'workout-program',
  'workout-program-detail',
  'article-list',
  'article',
  'article-history',
  'article-free',
]

function App({ Component, pageProps }) {
  return (
    <main className={montserrat.className}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister: asyncStoragePersister,
              maxAge: cacheTime,
              hydrateOptions: {},
              dehydrateOptions: {
                shouldDehydrateQuery: ({ queryKey }) => {
                  return Boolean(queryKey.find((key) => shouldPersist.includes(key)))
                },
              },
            }}
          >
            <TinodeProvider>
              <Component {...pageProps} />
            </TinodeProvider>
          </PersistQueryClientProvider>
        </ChakraProvider>
      </Provider>
    </main>
  )
}

export default dynamic(() => Promise.resolve(App), {
  ssr: false,
})
