import axios from '@/lib/axios'

export const payment = {
  state: {
    packages: [],
    chosenPackage: null,
    batchCalendar: [],
    price: null,
    paymentMethod: null,
    chosenBatch: null,
    createdAt: null,
    invoice: null,
  },
  reducers: {
    setPackages(state, payload) {
      return {
        ...state,
        packages: payload,
      }
    },
    setChosenPackage(state, payload) {
      return {
        ...state,
        chosenPackage: payload,
      }
    },
    setBatchCalendar(state, payload) {
      return {
        ...state,
        batchCalendar: payload,
      }
    },
    setPrice(state, payload) {
      return {
        ...state,
        price: payload,
      }
    },
    setPaymentMethod(state, payload) {
      return {
        ...state,
        paymentMethod: payload,
      }
    },
    setChosenBatch(state, payload) {
      return {
        ...state,
        chosenBatch: payload,
      }
    },
    setCreatedAt(state, payload) {
      return {
        ...state,
        createdAt: payload,
      }
    },
    setInvoice(state, payload) {
      return {
        ...state,
        invoice: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async getAllPackages(payload, rootState) {
      return await axios
        .get('/v1/packages', {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          dispatch.payment.setPackages(res.data.data)
          return res.data
        })
    },
    async getBatchCalendar(packageId, rootState) {
      return await axios
        .get('/v1/payment/get-batch-calendar', {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
          params: {
            package_id: packageId,
          },
        })
        .then((res) => {
          dispatch.payment.setBatchCalendar(res.data.data)
          return res.data
        })
    },
    async getPackagePrice(packageId, rootState) {
      return await axios
        .get('/v1/payment/get-price', {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
          params: {
            package_id: packageId,
          },
        })
        .then((res) => {
          dispatch.payment.setPrice(res.data.data)
          return res.data
        })
    },
    async createPayment(payload, rootState) {
      return await axios
        .post('/v1/payment/checkout/web', payload, {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
        })
        .then((res) => {
          return res.data
        })
    },
    async findPayment(payload, rootState) {
      return await axios
        .get('/v1/payment/find', {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${rootState.user.access_token}`,
          },
          params: {
            invoice: payload,
          },
        })
        .then((res) => {
          return res.data
        })
    },
  }),
}
