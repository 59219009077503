import { PatchUpdateHistoryApi } from '@/apis/WorkoutApi'
import { format } from 'date-fns'

const INITIAL_STATE = {
  status: 'idle',
  movements: [],
  showGallery: false,
  initialGalleryIndex: 0,
  screen: 'initial',
  currentSet: 1,
  totalSet: 0,
  progressIndex: 0,
  activeTab: 0,
}

export const workout = {
  state: INITIAL_STATE,
  reducers: {
    openGallery(state, { movements, initialGalleryIndex }) {
      return {
        ...state,
        showGallery: true,
        movements,
        initialGalleryIndex,
      }
    },
    closeGallery(state) {
      return {
        ...state,
        showGallery: false,
        movements: [],
        initialGalleryIndex: 0,
      }
    },
    setMovements(state, payload) {
      return {
        ...state,
        movements: payload,
      }
    },
    resetMovements(state) {
      return {
        ...state,
        movements: [],
      }
    },
    startWorkout(state, { movements, totalSet, currentSet }) {
      return {
        ...state,
        status: 'start',
        movements,
        screen: 'initial',
        currentSet,
        totalSet,
        progressIndex: 0,
      }
    },
    resetWorkout(state) {
      return {
        ...state,
        ...INITIAL_STATE,
      }
    },
    setScreen(state, { screen, progressIndex }) {
      return {
        ...state,
        screen,
        progressIndex,
      }
    },
    setCurrentSet(state, payload) {
      return {
        ...state,
        currentSet: payload,
      }
    },
    setActiveTab(state, payload) {
      return {
        ...state,
        activeTab: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async goToNextStep(_, { workout: state }) {
      const lastMovement = state.progressIndex + 1 === state.movements.length
      const lastSet = state.currentSet === state.totalSet
      const nextIndex = !lastMovement ? state.progressIndex + 1 : 0
      const nextSet = lastMovement ? state.currentSet + 1 : state.currentSet
      const payload = { screen: 'movement', progressIndex: nextIndex }

      const programId = state.movements[state.progressIndex].workout_program_id
      const today = format(new Date(), 'yyyy-MM-dd')

      if (lastSet && lastMovement) {
        // dispatch.workout.resetWorkout()
        payload.progressIndex = state.movements.length - 1
      } else {
        if (lastMovement) {
          const payload = {
            date: today,
            sets_completed: nextSet,
          }
          await PatchUpdateHistoryApi(programId, payload)
        }
      }

      dispatch.workout.setScreen(payload)
      dispatch.workout.setCurrentSet(lastSet && lastMovement ? state.totalSet : nextSet)

      return {
        lastSet: lastSet && lastMovement,
      }
    },
  }),
}
