const formatMealtime = (value, reverse) => {
  let mealtime = value.toLowerCase()
  if (mealtime === 'sarapan') {
    return 'breakfast'
  }
  if (mealtime === 'makan siang') {
    return 'lunch'
  }
  if (mealtime === 'makan malam') {
    return 'dinner'
  }
  if (mealtime === 'cemilan' || (mealtime === 'snack' && !reverse)) {
    return 'snack'
  }

  if (reverse) {
    if (mealtime === 'breakfast') {
      return 'Sarapan'
    }
    if (mealtime === 'lunch') {
      return 'Makan Siang'
    }
    if (mealtime === 'dinner') {
      return 'Makan Malam'
    }
    if (mealtime === 'snack') {
      return 'Cemilan'
    }
  }
}

export default formatMealtime
